import { IAudit } from '@alberta/konexi-shared';
import { AssetLoadable, ImageItem } from '@alberta/swodoc-document-generator';

import { TreeArray } from '../tree-array';

export class EmptyAssetLoader implements AssetLoadable {
  async load(audit: IAudit): Promise<ImageItem[]> {
    if (!audit.auditNodes) {
      return [
        {
          _id: 'mockId',
          uploadDate: new Date().toISOString(),
          data: 'mock-data',
          metadata: {
            auditNodeId: 'auditNodeId',
          },
        } as ImageItem,
      ];
    }
    const imagesItems = TreeArray.filterRec(
      audit.auditNodes,
      (f) =>
        f.nodeType_Id === 'signature' ||
        f.nodeType_Id === 'image' ||
        f.nodeType_Id === 'picture' ||
        f.nodeType_Id === 'signotec_signature' ||
        f.nodeType_Id === 'therapy_progress' ||
        f.nodeType_Id === 'document_scan_ios' ||
        f.nodeType_Id === 'woundmeasure'
    );
    return imagesItems.map((item) => {
      return {
        _id: item.nodeType_Id === 'picture' ? item.options : 'mockId',
        uploadDate: new Date().toISOString(),
        data: 'mock-data',
        metadata: {
          auditNodeId: item.id,
          type: item.nodeType_Id === 'therapy_progress' ? 103 : 1,
        },
      } as ImageItem;
    });
  }
}
